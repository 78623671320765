// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"ANIMATION_DELAY": ".4s",
	"mq-xs": "0",
	"mq-xsl": "544px",
	"mq-sm": "768px",
	"mq-md": "992px",
	"mq-lg": "1200px",
	"mq-xl": "1550px",
	"mq-xxl": "1800px",
	"headerHeight-xs": "60",
	"scrolledHeaderHeight-xs": "60"
};
module.exports = exports;
