import variables from '../../scss/_variables.scss';

const $document = $(document);
const $window = $(window);
const $html = $(document.documentElement);
const $body = $(document.body);
const $header = $('.js-header');
const SCALE = Number($html.css('font-size').replace('px', '')) / 16;
const ANIMATION_DELAY = parseFloat(variables.ANIMATION_DELAY.slice(0, -1)) * 1000; // delay for when animation is done

export {
    $document,
    $window,
    $html,
    $body,
    $header,
    ANIMATION_DELAY,
    SCALE,
};
