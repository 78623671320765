import { $html } from './utils/environment';
import setBrowser, { browsers } from './utils/browsers';
import setViewport from './utils/fullScreen';

// namings
const NAME = 'App';

class App {
    constructor () {
        Promise.all([App.domReady()]).then(this.import.bind(this));
    }

    static start () {
        return new App();
    }

    static domReady () {
        return new Promise(resolve => {
            document.addEventListener('DOMContentLoaded', resolve);
        });
    }

    static showPage () {
        $html.addClass('dom-is-loaded').removeClass('dom-is-loading');
    }

    import () {
        this.loadedControllers = [];

        // set viewport height
        setViewport();

        // set browser
        setBrowser();

        // load transitions
        if (!browsers.isIE()) import(/* webpackChunkName: "controllers/Transitions" */ './controllers/TransitionsController').then(e => e.default.init()).catch(error => console.error(error));

        // lazy load controllers
        const controllers = [
            import(/* webpackChunkName: "controllers/Modules" */ './controllers/ModuleController'),
            import(/* webpackChunkName: "controllers/Progressive" */ './controllers/ProgressiveController'),
            import(/* webpackChunkName: "controllers/Scroll" */ './controllers/ScrollController'),
        ];

        Promise.all(controllers)
            .then((results) => {
                console.log(`%c🚀 ${NAME} has been initialized`, 'color: yellow;');

                $.each(results, (i, e) => {
                    e.default.init();
                    this.loadedControllers.push(e.default);
                });

                App.showPage();
            })
            .catch(error => console.error(error));
    }

    /**
     * Reload the app
     *
     * Can be used after an ajax call to reload the DOM
     */
    reinit () {
        console.log(`%c🚀 ${NAME} has been reloaded`, 'color: yellow;');

        // reinit controllers
        $.each(this.loadedControllers, (i, e) => {
            if (e.name && e.name !== 'scroll') e.destroy().init();
            if (e.name && e.name === 'scroll') e.destroy().rebuild();
        });
    }
}

// start app
window[NAME] = App.start();
