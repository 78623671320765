import ResizeController from '../controllers/ResizeController';

const setVH = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export default function () {
    // set height
    setVH();

    // resize
    const resize = new ResizeController();
    resize.on('resize:end', () => setVH());
}
