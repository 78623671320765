import { $document, $html } from './environment';
import ResizeController from '../controllers/ResizeController';

export const browsers = {
    isMobileIE () {
        return /iemobile/i.test(navigator.userAgent.toLowerCase());
    },
    isMobileOpera () {
        return /opera mini/i.test(navigator.userAgent.toLowerCase());
    },
    isIOS () {
        return /iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase());
    },
    isBlackberry () {
        return /blackberry/i.test(navigator.userAgent.toLowerCase());
    },
    isMobileAndroid () {
        return /android.*mobile/.test(navigator.userAgent.toLowerCase());
    },
    isAndroid () {
        return this.isMobileAndroid() || (!this.isMobileAndroid() && /android/i.test(navigator.userAgent.toLowerCase()));
    },
    isFirefox () {
        return -1 < navigator.userAgent.toLowerCase().indexOf('firefox');
    },
    safari () {
        return navigator.userAgent.toLowerCase().match(/version\/[\d.]+.*safari/);
    },
    isSafari () {
        return !!this.safari() && !this.isAndroid();
    },
    isIEolderThan11 () {
        return -1 < navigator.userAgent.toLowerCase().indexOf('msie');
    },
    isIE11 () {
        return 0 < navigator.appVersion.indexOf('Trident/');
    },
    isIE () {
        return this.isIEolderThan11() || this.isIE11();
    },
    isMac () {
        return navigator.platform.indexOf('Mac') > -1;
    },
    isMobile () {
        return this.isMobileAndroid() || this.isBlackberry() || this.isIOS() || this.isMobileOpera() || this.isMobileIE();
    },
    isTouch () {
        return ('ontouchstart' in window) || (navigator.msMaxTouchPoints || navigator.maxTouchPoints) > 2;
    },
};

const setBrowser = () => {
    if (browsers.isTouch())
    {
        $html.addClass('is-touch');
    }
    else
    {
        $html.removeClass('is-touch');
    }

    if (browsers.isIE())
    {
        import(/* webpackChunkName: "ie" */ '../../scss/ie.scss').then(() => $document.trigger('rebuild.Scroll'));
        $html.addClass('is-ie');
    }
    else
    {
        $html.removeClass('is-ie');
    }

    if (browsers.isFirefox())
    {
        $html.addClass('is-firefox');
    }
    else
    {
        $html.removeClass('is-firefox');
    }

    if (browsers.isIOS())
    {
        $html.addClass('is-ios');
    }
    else
    {
        $html.removeClass('is-ios');
    }
};

export default function () {
    // set browser classes
    setBrowser();
    // resize
    const resize = new ResizeController();
    resize.on('resize:end', () => setBrowser());
}
