/* eslint-disable no-plusplus */
import eventEmitter from 'wolfy87-eventemitter';

export default class ResizeController extends eventEmitter {
    constructor (threshold = 100) {
        super();

        this.threshold = threshold; // amount of pixels that are resized must exceed this amount to trigger the event

        this.lastWidth = window.innerWidth; // store the previous window width
        this.lastHeight = window.innerHeight; // store the previous window height

        this.onResizeHandle = this.onResize.bind(this);

        window.addEventListener('resize', this.onResizeHandle);
        window.addEventListener('orientationchange', this.onResizeHandle);
    }

    onResize () {
        if (Math.abs(this.lastHeight - window.innerHeight) > this.threshold || Math.abs(this.lastWidth - window.innerWidth) > this.threshold)
        {
            if (!this.started)
            {
                this.started = true;
                this.times = 0;

                this.emitEvent('resize:start');
            }

            if (this.handle != null)
            {
                this.times = 0;

                window.cancelAnimationFrame(this.handle);
            }

            this.handle = window.requestAnimationFrame(function tick () {
                if (++this.times === 10)
                {
                    this.handle = null;
                    this.started = false;
                    this.times = 0;

                    this.lastWidth = window.innerWidth;
                    this.lastHeight = window.innerHeight;

                    this.emitEvent('resize:end');
                }
                else
                {
                    this.handle = window.requestAnimationFrame(tick.bind(this));
                }
            }.bind(this));
        }
    }

    destroy () {
        window.removeEventListener('resize', this.onResizeHandle);
        window.removeEventListener('orientationchange', this.onResizeHandle);

        this.removeAllListeners();
    }
}
